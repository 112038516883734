import { SideBarTreeSection } from '../components/SideBar'
import { REPORT_URLS } from '../pages/reports/utils/urls'

export const Path = {
  ANNOTATION: '/annotation',
  ARTIFICIAL_ETFS: '/reports/artificialetfs',
  ARTIFICIAL_ETFS_ANALYSIS: '/reports/artificialetfs/analysis',
  ARTIFICIAL_ETFS_LAUNCHPAD: '/reports/artificialetfs/launchpad',
  BASKETS: '/baskets',
  CLASSIFICATION_MANAGER: '/reports/classification_manager',
  COOLTABLE: '/cooltable',
  CVM: '/reports/cvm',
  CVM_ANALYSIS: REPORT_URLS['cvm'],
  CVM_LONG_BIAS_LAUNCHPAD: '/reports/cvm/long_bias_launchpad',
  CVM_LONG_ONLY_LAUNCHPAD: '/reports/cvm/long_only_launchpad',
  DEPUTIES_VOTES: '/reports/deputies_votes',
  ESG: '/esg',
  ESG_EDIT_FRAMEWORK: '/esg/edit-framework',
  ESG_EDIT_RESPONSE: '/esg/edit-response',
  ESG_FRAMEWORK_MANAGER: '/esg/framework-manager',
  ESG_REPORTS: '/esg/reports',
  HOME: '/',
  INSIDER_TRADING: REPORT_URLS['insider-trading'],
  LAUNCHPAD: '/launchpad',
  LAUNCHPAD_REAL_ESTATE: '/launchpad/real_estate',
  LOGIN: '/login',
  MACRO_REPORT: REPORT_URLS['macro-report'],
  MARKET_STRUCTURE: REPORT_URLS['market-structure'],
  MERGE_AND_ACQUISITIONS: '/reports/merge_and_acquisitions',
  MERGE_AND_ACQUISITIONS_ANALYSIS: '/reports/merge_and_acquisitions/analysis',
  MERGE_AND_ACQUISITIONS_FILES: '/reports/merge_and_acquisitions/files',
  NOTIFICATIONS_MANAGER: '/reports/notifications',
  PERFORMANCE_ATTRIBUTION: '/reports/performance_attribution',
  PORTFOLIO_ANALYSIS: REPORT_URLS['portfolio-analysis'],
  PORTFOLIO: '/portfolio',
  PORTFOLIO_FUNDS_COMPARISON: '/portfolio/funds-comparison',
  PORTFOLIO_OPTIONS: '/portfolio/options',
  PORTFOLIO_PAIR_TRADING: '/portfolio/pair-trading',
  PORTFOLIO_VS_IBOV: '/portfolio/vs-ibov',
  PORTFOLIO_PERFORMANCE_FEE: '/portfolio/performance-fee',
  PORTFOLIO_REAL_ESTATE: '/portfolio/real_estate',
  PORTFOLIO_REAL_ESTATE_FUNDS_COMPARISON:
    '/portfolio/real_estate/funds_comparison',
  PORTFOLIO_REAL_ESTATE_OPTIONS: '/portfolio/real_estate/options',
  PORTFOLIO_REAL_ESTATE_PAIR_TRADING: '/portfolio/real_estate/pair_trading',
  PORTFOLIO_REAL_ESTATE_IMOBBV: '/portfolio/real_estate/vs_imobbv',
  PRESENTATIONS: '/presentations',
  PRESENTATIONS_BANKS: '/presentations/banks',
  PRESENTATIONS_BANKS_DEFAULT: '/presentations/banks_default',
  PRESENTATIONS_COMMODITIES_COVERAGE: '/presentations/commodities_coverage',
  PRESENTATIONS_DIVIDENDS: '/presentations/dividends',
  PRESENTATIONS_HAPVIDA: '/presentations/hapvida',
  PRESENTATIONS_METALS_AND_MINING: '/presentations/metals_and_mining',
  PRESENTATIONS_MULTIFACTOR_MODELS: '/presentations/multifactor_models',
  PRESENTATIONS_NORMS: '/presentations/norms',
  PRESENTATIONS_PORTFOLIO_INSIGHTS: '/presentations/portfolio_insights',
  PRESENTATIONS_PERFORMANCE_ATTRIBUTION:
    '/presentations/performance_attribution',
  PRESENTATIONS_SPX_PAPERS: '/presentations/spx_papers',
  PRESENTATIONS_STRATEGY: '/presentations/strategy',
  PRESENTATIONS_STRATEGY_SPX: '/presentations/strategy_spx',
  PRESENTATIONS_TELECOM: '/presentations/telecom',
  PRESENTATIONS_UTILITIES: '/presentations/utilities',
  PRESENTATIONS_ULOREA_COVERAGE: '/presentations/ulorea_coverage',
  PRESENTATIONS_VALUATION_SPX: '/presentations/valuation_spx',
  REGISTER: '/register',
  REGISTER_COMPLETE: '/register/complete',
  REGISTER_CREATEACCOUNT: '/register/createaccount',
  REPORTS: '/reports',
  REPORTS_AGRO: '/reports/agro',
  REPORTS_AGRO_ANALYSIS: REPORT_URLS['agro'],
  REPORTS_AGRO_FILES: '/reports/agro/files',
  REPORTS_AIRLINES: '/reports/airlines',
  REPORTS_APPAREL: REPORT_URLS['apparel'],
  REPORTS_BANKS: '/reports/banks',
  REPORTS_BANKS_ANALYSIS: REPORT_URLS['banks'],
  REPORTS_BANKS_FILES: '/reports/banks/files',
  REPORTS_PROTEINS: '/reports/proteins',
  REPORTS_PROTEINS_ANALYSIS: REPORT_URLS['proteins'],
  REPORTS_PROTEINS_AUS_BEEF: REPORT_URLS['proteins-aus-beef'],
  REPORTS_PROTEINS_BZ_BEEF: REPORT_URLS['proteins-bz-beef'],
  REPORTS_PROTEINS_BZ_CHICKEN: REPORT_URLS['proteins-bz-chicken'],
  REPORTS_PROTEINS_GLOBAL_MARKETS: REPORT_URLS['proteins-global-markets'],
  REPORTS_PROTEINS_US_BEEF: REPORT_URLS['proteins-us-beef'],
  REPORTS_PROTEINS_US_CHICKEN: REPORT_URLS['proteins-us-chicken'],
  REPORTS_PROTEINS_US_PORK: REPORT_URLS['proteins-us-pork'],
  REPORTS_PROTEINS_VALUATION: REPORT_URLS['proteins-valuation'],
  REPORTS_PROTEINS_FILES: '/reports/proteins/files',
  REPORTS_CAPITAL_MARKETS: REPORT_URLS['capital-markets'],
  REPORTS_CAR_RENTAL: REPORT_URLS['car-rental'],
  REPORTS_COMMODITIES: REPORT_URLS['commodities'],
  REPORTS_CONSUMER: REPORT_URLS['consumer'],
  REPORTS_CONSUMER_MONITOR: REPORT_URLS['consumer-monitor'],
  REPORTS_CRYPTO: REPORT_URLS['crypto'],
  REPORTS_CRYPTO_LAUNCHPAD: '/reports/crypto/launchpad',
  REPORTS_DASHBOARD: '/reports/dashboard',
  REPORTS_DISTRIBUTION: REPORT_URLS['distribution'],
  REPORTS_EARNINGS_SURPRISE: REPORT_URLS['earnings-surprise'],
  REPORTS_EDUCATION: '/reports/education',
  REPORTS_EDUCATION_SUPERIOR: '/reports/education/superior',
  REPORTS_EDUCATION_VALUATION: REPORT_URLS['education-valuation'],
  REPORTS_ENERGY_SHORTAGE: REPORT_URLS['energy-shortage-overview'],
  REPORTS_EXCHANGE: REPORT_URLS['exchange'],
  REPORTS_E_COMMERCE: '/reports/ecommerce',
  REPORTS_E_COMMERCE_AMAZON: REPORT_URLS['ecommerce-amazon'],
  REPORTS_E_COMMERCE_ENJOEI: REPORT_URLS['ecommerce-enjoei'],
  REPORTS_E_COMMERCE_LAUNCHPAD: '/reports/ecommerce/launchpad',
  REPORTS_E_COMMERCE_MELI: REPORT_URLS['ecommerce-meli'],
  REPORTS_E_COMMERCE_ROTHYS: REPORT_URLS['ecommerce-rothys'],
  REPORTS_E_COMMERCE_SELLERS_MELI: REPORT_URLS['ecommerce-sellers-meli'],
  REPORTS_E_COMMERCE_VALUATION: REPORT_URLS['ecommerce-valuation'],
  REPORTS_E_COMMERCE_WESTWING: REPORT_URLS['ecommerce-westwing'],
  REPORTS_FINANCIAL_FRAUD: REPORT_URLS['financial-fraud'],
  REPORTS_FINANCIAL_STATEMENT: '/reports/financial_statement',
  REPORTS_FINANCIAL_STATEMENT_IBOV: REPORT_URLS['financial-statement-ibov'],
  REPORTS_FINANCIAL_STATEMENT_NON_FINANCIAL:
    REPORT_URLS['financial-statement-non-financial'],
  REPORTS_FINANCIAL_STATEMENT_FINANCIALS:
    REPORT_URLS['financial-statement-financials'],
  REPORTS_FLOW: REPORT_URLS['flow'],
  REPORTS_GLOBAL_SCENARIO: REPORT_URLS['global-scenario'],
  REPORTS_HEALTHCARE: '/reports/healthcare',
  REPORTS_HEALTHCARE_MEMBROS: '/reports/healthcare/membros',
  REPORTS_HEALTHCARE_OPERADORAS: '/reports/healthcare/operadoras',
  REPORTS_HEALTHCARE_OPERATORS_BY_REGION:
    '/reports/healthcare/operators_by_region',
  REPORTS_HEALTHCARE_UPDATE_MENSAL: '/reports/healthcare/update_mensal',
  REPORTS_HEALTHCARE_VALUATION: REPORT_URLS['healthcare-valuation'],
  REPORTS_HOMEBUILDERS: REPORT_URLS['homebuilders'],
  REPORTS_INDUSTRIALS: REPORT_URLS['industrials'],
  REPORTS_INSURANCE: REPORT_URLS['insurance'],
  REPORTS_LAUNCHPAD_MONITOR: '/reports/launchpad_monitor',
  REPORTS_LITHIUM: REPORT_URLS['lithium'],
  REPORTS_METALS_AND_MINING: '/reports/metalsandmining',
  REPORTS_METALS_AND_MINING_ANALYSIS: REPORT_URLS['metals-and-mining'],
  REPORTS_METALS_AND_MINING_FILES: '/reports/metalsandmining/files',
  REPORTS_METALS_AND_MINING_LAUNCHPAD: '/reports/metalsandmining/launchpad',
  REPORTS_NLP_MODELS: REPORT_URLS['nlp-models'],
  REPORTS_OIL: '/reports/oil',
  REPORTS_OIL_ANALYSIS: REPORT_URLS['oil'],
  REPORTS_OIL_FILES: '/reports/oil/files',
  REPORTS_PAYMENTS: REPORT_URLS['payments'],
  REPORTS_POSITIONS: REPORT_URLS['positions'],
  REPORTS_UTILITIES: REPORT_URLS['utilities'],
  REPORTS_PULP_AND_PAPER: '/reports/pulp_and_paper',
  REPORTS_PULP_AND_PAPER_ANALYSIS: REPORT_URLS['pulp-and-paper'],
  REPORTS_PULP_AND_PAPER_FILES: '/reports/pulp_and_paper/files',
  REPORTS_PULP_AND_PAPER_LAUNCHPAD: '/reports/pulp_and_paper/launchpad',
  REPORTS_QUANT_STRATEGY: REPORT_URLS['quant-strategy'],
  REPORTS_RETAIL: REPORT_URLS['retail'],
  REPORTS_SAAS: '/reports/saas',
  REPORTS_SOCIAL_MEDIA: '/reports/social_media',
  REPORTS_SUGAR: '/reports/sugar',
  REPORTS_SUGAR_ANALYSIS: REPORT_URLS['sugar'],
  REPORTS_SUGAR_FILES: '/reports/sugar/files',
  REPORTS_SURPRISE: REPORT_URLS['surprise'],
  REPORTS_TELECOM: REPORT_URLS['telecom'],
  REPORTS_TEST: REPORT_URLS['test'],
  REPORTS_TRANSCRIPTS: REPORT_URLS['transcripts'],
  REPORTS_UPSIDE: REPORT_URLS['upside'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_ANFAVEA: REPORT_URLS['brazil-activity-anfavea'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_FORECASTS:
    REPORT_URLS['brazil-activity-forecasts'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_GDP: REPORT_URLS['brazil-activity-gdp'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_PIM: REPORT_URLS['brazil-activity-pim'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_PMC: REPORT_URLS['brazil-activity-pmc'],
  REPORTS_MACRO_BRAZIL_ACTIVITY_PMS: REPORT_URLS['brazil-activity-pms'],
  REPORTS_MACRO_BRAZIL_BOP: REPORT_URLS['brazil-bop'],
  REPORTS_MACRO_BRAZIL_ICF_BCB: REPORT_URLS['brazil-icf-bcb'],
  REPORTS_MACRO_BRAZIL_CREDIT: REPORT_URLS['brazil-credit'],
  REPORTS_MACRO_BRAZIL_ENERGY_CCEE: REPORT_URLS['brazil-energy-ccee'],
  REPORTS_MACRO_BRAZIL_FISCAL: REPORT_URLS['brazil-fiscal'],
  REPORTS_MACRO_BRAZIL_FOCUS_EXPECTATIONS:
    REPORT_URLS['brazil-focus-expectations'],
  REPORTS_MACRO_BRAZIL_JGP_EXPECTATIONS: REPORT_URLS['brazil-jgp-expectations'],
  REPORTS_MACRO_BRAZIL_LABOUR_MARKET: REPORT_URLS['brazil-labour-market'],
  REPORTS_MACRO_BRAZIL_PRE_COPOM: REPORT_URLS['brazil-pre-copom'],
  REPORTS_MACRO_BRAZIL_PRICES_FGV: REPORT_URLS['brazil-prices-fgv'],
  REPORTS_MACRO_BRAZIL_PRICES_FIPE: REPORT_URLS['brazil-prices-fipe'],
  REPORTS_MACRO_BRAZIL_PRICES_INFLATION_MONITOR:
    REPORT_URLS['brazil-prices-inflation-monitor'],
  REPORTS_MACRO_BRAZIL_PRICES_IPCA: REPORT_URLS['brazil-prices-ipca'],
  REPORTS_MACRO_BRAZIL_PRICES_IPCA_15: REPORT_URLS['brazil-prices-ipca-15'],
  REPORTS_MACRO_BRAZIL_PRICES_IPCA_ENCADEADOS:
    REPORT_URLS['brazil-prices-ipca-encadeado'],
  REPORTS_MACRO_BRAZIL_PRICES_IPCA_MODELS:
    REPORT_URLS['brazil-prices-ipca-models'],
  REPORTS_MACRO_BRAZIL_REALTIME_ICBR: REPORT_URLS['brazil-realtime-icbr'],
  REPORTS_MACRO_BRAZIL_SURVEYS_FGV: REPORT_URLS['brazil-surveys-fgv'],
  REPORTS_MACRO_CHINA_ACTIVITY_PROXY: REPORT_URLS['china-activity-proxy'],
  REPORTS_MACRO_COUNTRY_AUSTRALIA: REPORT_URLS['country-australia'],
  REPORTS_MACRO_COUNTRY_CANADA: REPORT_URLS['country-canada'],
  REPORTS_MACRO_COUNTRY_CHILE: REPORT_URLS['country-chile'],
  REPORTS_MACRO_COUNTRY_CHINA: REPORT_URLS['country-china'],
  REPORTS_MACRO_COUNTRY_COLOMBIA: REPORT_URLS['country-colombia'],
  REPORTS_MACRO_COUNTRY_EURO_AREA: REPORT_URLS['country-euro-area'],
  REPORTS_MACRO_COUNTRY_GERMANY: REPORT_URLS['country-germany'],
  REPORTS_MACRO_COUNTRY_INDIA: REPORT_URLS['country-india'],
  REPORTS_MACRO_COUNTRY_JAPAN: REPORT_URLS['country-japan'],
  REPORTS_MACRO_COUNTRY_MEXICO: REPORT_URLS['country-mexico'],
  REPORTS_MACRO_COUNTRY_NEW_ZEALAND: REPORT_URLS['country-new-zealand'],
  REPORTS_MACRO_COUNTRY_NORWAY: REPORT_URLS['country-norway'],
  REPORTS_MACRO_COUNTRY_RUSSIA: REPORT_URLS['country-russia'],
  REPORTS_MACRO_COUNTRY_SOUTH_AFRICA: REPORT_URLS['country-south-africa'],
  REPORTS_MACRO_COUNTRY_SOUTH_KOREA: REPORT_URLS['country-south-korea'],
  REPORTS_MACRO_COUNTRY_SWEDEN: REPORT_URLS['country-sweden'],
  REPORTS_MACRO_COUNTRY_UNITED_KINGDOM: REPORT_URLS['country-united-kingdom'],
  REPORTS_MACRO_COUNTRY_UNITED_STATES: REPORT_URLS['country-united-states'],
  REPORTS_MACRO_EM_CHILE: REPORT_URLS['em-chile'],
  REPORTS_MACRO_EM_COLOMBIA: REPORT_URLS['em-colombia'],
  REPORTS_MACRO_EM_MEXICO: REPORT_URLS['em-mexico'],
  REPORTS_MACRO_EM_PM_MEETINGS: REPORT_URLS['em-monetary-policy-meetings'],
  REPORTS_MACRO_EM_SOUTH_AFRICA: REPORT_URLS['em-south-africa'],
  REPORTS_MACRO_FX_AND_RM_BEER: '/reports/macro/fx_and_rm_beer',
  REPORTS_MACRO_FX_AND_RM_BOND: '/reports/macro/fx_and_rm_bond',
  REPORTS_MACRO_FX_AND_RM_FLOWS_AND_POSITIONS:
    '/reports/macro/fx_and_rm_flows_and_positions',
  REPORTS_MACRO_GR_BIS_CREDIT: REPORT_URLS['gr-bis-credit'],
  REPORTS_MACRO_GR_CITI_SURPRISE: REPORT_URLS['gr-citi-economic-surprise'],
  REPORTS_MACRO_GR_CPI: REPORT_URLS['gr-cpi'],
  REPORTS_MACRO_GR_GDP_WEEKLY_TRACKER: REPORT_URLS['gr-gdp-weekly-tracker'],
  REPORTS_MACRO_GR_HLW_MODELS: REPORT_URLS['gr-hlw-models'],
  REPORTS_MACRO_GR_INDUSTRIALS_PRODUCTION:
    REPORT_URLS['gr-industrial-production'],
  REPORTS_MACRO_GR_LEADING_INDICATORS: REPORT_URLS['gr-leading-indicators'],
  REPORTS_MACRO_GR_MANUFACTURING_PRODUCTION:
    REPORT_URLS['gr-manufacturing-production'],
  REPORTS_MACRO_GR_NAIRU: REPORT_URLS['gr-nairu'],
  REPORTS_MACRO_GR_NOWCAST: REPORT_URLS['gr-nowcast'],
  REPORTS_MACRO_GR_PMI: REPORT_URLS['gr-pmi'],
  REPORTS_MACRO_GR_POLICY_RATE: REPORT_URLS['gr-policy-rate'],
  REPORTS_MACRO_GR_TAYLOR_RULE: REPORT_URLS['gr-taylor-rule'],
  REPORTS_MACRO_GR_TRADE_MONITOR: REPORT_URLS['gr-trade-monitor'],
  REPORTS_MACRO_HIGH_FREQUENCY_GOOGLE_TREND:
    REPORT_URLS['high-frequency-google-trends'],
  REPORTS_MACRO_HIGH_FREQUENCY_TRUFLATION:
    REPORT_URLS['high-frequency-truflation'],
  REPORTS_MACRO_US_CPI: REPORT_URLS['us-cpi'],
  REPORTS_MACRO_US_FOMC: REPORT_URLS['us-fomc'],
  REPORTS_MACRO_US_INFLATION_EXPECTATIONS:
    REPORT_URLS['us-inflation-expectations'],
  REPORTS_MACRO_US_PAYROLL: REPORT_URLS['us-payroll'],
  REPORTS_MACRO_US_PCE_INFLATION: REPORT_URLS['us-pce-inflation'],
  REPORTS_MACRO_US_PERSONAL_INCOME: REPORT_URLS['us-personal-income'],
  REPORTS_MACRO_US_RECESSION_PROBABILITY:
    REPORT_URLS['us-recession-probability'],
  REPORTS_MACRO_US_REGIONS_FED_SURVEYS: REPORT_URLS['us-regions-fed-surveys'],
  REPORTS_MACRO_US_RETAIL_SALES: REPORT_URLS['us-retail-sales'],
  REPORT_MANAGER: '/report_manager',
  REPORT_MANAGER_CONTROLS: '/report_manager/controls',
  REPORT_MANAGER_ELEMENTS: '/report_manager/elements',
  REPORT_MANAGER_REPORTS: '/report_manager/reports',
  REPORT_MANAGER_PRESENTATIONS: '/report_manager/presentations',
  REPORT_MANAGER_LAUNCHPADS: '/report_manager/launchpads',
  RESET_PASSWORD: '/resetpassword',
  RESET_PASSWORD_REQUEST: '/resetpassword/request',
  RESET_PASSWORD_FORM: '/resetpassword/form',
  SATELLITE: REPORT_URLS['satellite'],
  SCREENING: '/reports/screening',
  SEARCH: '/search',
  SHARED_CHART: '/sharedchart',
  SHAREHOLDERS_MEETING: '/reports/shareholders_meeting',
  SHORT_SQUEEZE: '/reports/short_squeeze',
  SHORT_SQUEEZE_BZ_TRADING: '/reports/short_squeeze/bz_trading',
  SHORT_SQUEEZE_MACRO: REPORT_URLS['short-squeeze-macro'],
  SHORT_SQUEEZE_US_TRADING: '/reports/short_squeeze/us_trading',
  TECH_DISRUPTION: REPORT_URLS['tech-disruption'],
  TEST: '/test',
  TEST_CHART: '/test/chart',
  TEST_REPORT: '/test/report',
  UPDATE_SEARCH: '/update_search',
  USER: '/user',
  USER_DASHBOARD: '/user/dashboard',
  USER_NOTIFICATIONS: '/user/notifications',
  VALUATION: '/valuation',
  VALUATION_REPORT: REPORT_URLS['valuation-report'],
  VALUATION_SPX: REPORT_URLS['valuation-spx'],
  VESSELS: '/reports/vessels',
}

export const chartPalette = [
  '#16164D',
  '#297F0D',
  '#808080',
  '#e7b619',
  '#f57350',
  '#38387C',
  '#56CC2B',
  '#C0C0C0',
  '#FFD05A',
]

export const COMPASS_V1_PATH = 'https://compass.jgp.com.br'

const esgSubtree: SideBarTreeSection = {
  title: 'ESG',
  permissions: ['v2:compass:esg'],
  children: [
    {
      title: 'ESG Plataforma',
      path: 'https://esg-reports.jgp.com.br/reports',
    },
    {
      title: 'Gerenciamento de frameworks',
      path: Path.ESG_FRAMEWORK_MANAGER,
      permissions: ['v2:compass:esg:frameworks'],
    },
    {
      title: 'Dashboard',
      path: COMPASS_V1_PATH + '/credit/esgdashboard',
    },
    {
      title: 'Events',
      path: COMPASS_V1_PATH + '/esg/events',
    },
    {
      title: 'Emissões Certificadas',
      path: COMPASS_V1_PATH + '/esg/greenbonds',
    },
  ],
}

const equitySubtree: SideBarTreeSection = {
  title: 'Equity',
  permissions: ['v2:compass:equity'],
  children: [
    {
      title: 'Gestão',
      children: [
        {
          title: 'Launchpad',
          path: Path.LAUNCHPAD,
          permissions: ['v2:compass:equity:launchpad'],
        },
        {
          title: 'Portfolio',
          path: Path.PORTFOLIO,
          permissions: ['v2:compass:equity:portfolio'],
        },
        {
          title: 'Portfolio analysis',
          path: Path.PORTFOLIO_ANALYSIS,
          permissions: ['v2:compass:equity:portfolio_analysis'],
        },
        {
          title: 'Performance attribution',
          path: Path.PERFORMANCE_ATTRIBUTION,
          permissions: ['v2:compass:equity:performance_attribution'],
        },
        {
          title: 'Dashboard',
          path: Path.REPORTS_DASHBOARD,
          permissions: ['v2:compass:equity:dashboard_report'],
        },
      ],
    },
    {
      title: 'Strategy',
      children: [
        {
          title: 'AGO/E',
          path: Path.SHAREHOLDERS_MEETING,
          permissions: ['v2:compass:equity:shareholders_meeting'],
        },
        {
          title: 'Crypto',
          path: Path.REPORTS_CRYPTO,
          permissions: ['v2:compass:equity:crypto'],
        },
        {
          title: 'Cvm',
          permissions: ['v2:compass:equity:cvm'],
          children: [
            {
              title: 'Launchpad- Long bias',
              path: Path.CVM_LONG_BIAS_LAUNCHPAD,
            },
            {
              title: 'Launchpad- Long only',
              path: Path.CVM_LONG_ONLY_LAUNCHPAD,
            },
            {
              title: 'Analysis',
              path: Path.CVM_ANALYSIS,
            },
          ],
        },
        {
          title: 'Earnings',
          path: COMPASS_V1_PATH + '/equity/earnings',
        },
        {
          title: 'Earnings surprise',
          permissions: ['v2:compass:equity:global_scenario'],
          path: Path.REPORTS_EARNINGS_SURPRISE,
        },
        {
          title: 'ETF',
          permissions: ['v2:compass:equity:artificial_etfs'],
          children: [
            {
              title: 'Analysis',
              path: Path.ARTIFICIAL_ETFS_ANALYSIS,
            },
            {
              title: 'Launchpad',
              path: Path.ARTIFICIAL_ETFS_LAUNCHPAD,
            },
          ],
        },
        {
          title: 'Financial fraud',
          permissions: ['v2:compass:equity:financial_fraud'],
          path: Path.REPORTS_FINANCIAL_FRAUD,
        },
        {
          title: 'Financial Statement',
          permissions: ['v2:compass:equity:financial_statement'],
          children: [
            {
              title: 'Non-Financial',
              path: Path.REPORTS_FINANCIAL_STATEMENT_NON_FINANCIAL,
            },
            {
              title: 'IBOV',
              path: Path.REPORTS_FINANCIAL_STATEMENT_IBOV,
            },
            {
              title: 'Financials',
              path: Path.REPORTS_FINANCIAL_STATEMENT_FINANCIALS,
            },
          ],
        },
        {
          title: 'Flow',
          path: Path.REPORTS_FLOW,
          permissions: ['v2:compass:equity:flow'],
        },
        {
          title: 'Global scenario',
          path: Path.REPORTS_GLOBAL_SCENARIO,
          permissions: ['v2:compass:equity:global_scenario'],
        },
        {
          title: 'Insider Trading',
          path: Path.INSIDER_TRADING,
          permissions: ['v2:compass:equity:insider_trading'],
        },
        {
          title: 'Market structure',
          path: Path.MARKET_STRUCTURE,
          permissions: ['v2:compass:equity:market_structure'],
        },
        {
          title: 'Merge and acquisitions',
          permissions: ['v2:compass:equity:merge_and_acquisitions'],
          children: [
            {
              title: 'Analysis',
              path: Path.MERGE_AND_ACQUISITIONS_ANALYSIS,
            },
            {
              title: 'Files',
              path: Path.MERGE_AND_ACQUISITIONS_FILES,
            },
          ],
        },
        {
          title: 'Quant strategy',
          path: Path.REPORTS_QUANT_STRATEGY,
          permissions: ['v2:compass:equity:quant_strategy'],
        },
        {
          title: 'Screening',
          path: Path.SCREENING,
          permissions: ['v2:compass:equity:screening'],
        },
        {
          title: 'Short squeeze',
          permissions: ['v2:compass:equity:short_squeeze'],
          children: [
            {
              title: 'Trading - Us',
              path: Path.SHORT_SQUEEZE_US_TRADING,
            },
            {
              title: 'Trading - Bz',
              path: Path.SHORT_SQUEEZE_BZ_TRADING,
            },
            {
              title: 'Macro',
              path: Path.SHORT_SQUEEZE_MACRO,
            },
          ],
        },
        {
          title: 'Surprise',
          path: Path.REPORTS_SURPRISE,
          permissions: ['v2:compass:equity:surprise'],
        },
        {
          title: 'Positions',
          path: Path.REPORTS_POSITIONS,
          permissions: ['v2:compass:equity:options_report'],
        },
        {
          title: 'Tech disruption',
          path: Path.TECH_DISRUPTION,
          permissions: ['v2:compass:equity:tech_disruption'],
        },
        {
          title: 'Transcripts',
          path: Path.REPORTS_TRANSCRIPTS,
          permissions: ['v2:compass:equity:transcripts'],
        },
        {
          title: 'Upside',
          path: Path.REPORTS_UPSIDE,
          permissions: ['v2:compass:equity:upside'],
        },
        {
          title: 'Valuation',
          path: Path.VALUATION,
          permissions: ['v2:compass:equity:valuation'],
        },
        {
          title: 'Valuation Report',
          path: Path.VALUATION_REPORT,
          permissions: ['v2:compass:equity:valuation_report'],
        },
        {
          title: 'Valuation S&P',
          path: Path.VALUATION_SPX,
          permissions: ['v2:compass:equity:valuation_spx'],
        },
      ],
    },
    {
      title: 'Sector',
      children: [
        {
          title: 'Agro',
          permissions: ['v2:compass:equity:agro'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_AGRO_ANALYSIS,
            },
            {
              title: 'Files',
              path: Path.REPORTS_AGRO_FILES,
            },
          ],
        },
        {
          title: 'Airlines',
          path: Path.REPORTS_AIRLINES,
          permissions: ['v2:compass:equity:airlines'],
        },
        {
          title: 'Apparel',
          path: Path.REPORTS_APPAREL,
          permissions: ['v2:compass:equity:apparel'],
        },
        {
          title: 'Banks',
          permissions: ['v2:compass:equity:banks'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_BANKS_ANALYSIS,
            },
            {
              title: 'Files',
              path: Path.REPORTS_BANKS_FILES,
            },
          ],
        },
        {
          title: 'Capital markets',
          path: Path.REPORTS_CAPITAL_MARKETS,
          permissions: ['v2:compass:equity:capital_markets'],
        },
        {
          title: 'Car rental',
          path: Path.REPORTS_CAR_RENTAL,
          permissions: ['v2:compass:equity:car_rental'],
        },
        {
          title: 'Commodities',
          path: Path.REPORTS_COMMODITIES,
          permissions: ['v2:compass:equity:commodities'],
        },
        {
          title: 'Consumer',
          path: Path.REPORTS_CONSUMER,
          permissions: ['v2:compass:equity:consumer'],
        },
        {
          title: 'Consumer monitor',
          path: Path.REPORTS_CONSUMER_MONITOR,
          permissions: ['v2:compass:equity:consumer_monitor'],
        },
        {
          title: 'Distribution',
          path: Path.REPORTS_DISTRIBUTION,
          permissions: ['v2:compass:equity:distribution'],
        },
        {
          title: 'Ecommerce',
          permissions: ['v2:compass:equity:ecommerce'],
          children: [
            {
              title: 'Amazon',
              path: Path.REPORTS_E_COMMERCE_AMAZON,
            },
            {
              title: 'Enjoei',
              path: Path.REPORTS_E_COMMERCE_ENJOEI,
            },
            {
              title: 'Meli',
              path: Path.REPORTS_E_COMMERCE_MELI,
            },
            {
              title: 'Sellers Meli',
              path: Path.REPORTS_E_COMMERCE_SELLERS_MELI,
            },
            {
              title: "Rothy's",
              path: Path.REPORTS_E_COMMERCE_ROTHYS,
            },
            {
              title: 'Westwing',
              path: Path.REPORTS_E_COMMERCE_WESTWING,
            },
            {
              title: 'Launchpad',
              path: Path.REPORTS_E_COMMERCE_LAUNCHPAD,
            },
            {
              title: 'Valuation',
              path: Path.REPORTS_E_COMMERCE_VALUATION,
            },
          ],
        },
        {
          title: 'Education',
          permissions: ['v2:compass:equity:education'],
          children: [
            {
              title: 'Superior',
              path: Path.REPORTS_EDUCATION_SUPERIOR,
            },
            {
              title: 'Valuation',
              path: Path.REPORTS_EDUCATION_VALUATION,
            },
          ],
        },
        {
          title: 'Energy Shortage',
          path: Path.REPORTS_ENERGY_SHORTAGE,
          permissions: ['v2:compass:equity:energy_shortage'],
        },
        {
          title: 'Exchange',
          path: Path.REPORTS_EXCHANGE,
          permissions: ['v2:compass:equity:exchange'],
        },
        {
          title: 'Healthcare',
          permissions: ['v2:compass:equity:healthcare'],
          children: [
            {
              title: 'Dados por operadoras',
              path: Path.REPORTS_HEALTHCARE_OPERADORAS,
            },
            {
              title: 'Membros por região',
              path: Path.REPORTS_HEALTHCARE_MEMBROS,
            },
            {
              title: 'Operadoras por região',
              path: Path.REPORTS_HEALTHCARE_OPERATORS_BY_REGION,
            },
            {
              title: 'Update mensal',
              path: Path.REPORTS_HEALTHCARE_UPDATE_MENSAL,
            },
            {
              title: 'Valuation',
              path: Path.REPORTS_HEALTHCARE_VALUATION,
            },
          ],
        },
        {
          title: 'Homebuilders',
          path: Path.REPORTS_HOMEBUILDERS,
          permissions: ['v2:compass:equity:homebuilders'],
        },
        {
          title: 'Industrials',
          path: Path.REPORTS_INDUSTRIALS,
          permissions: ['v2:compass:equity:industrials'],
        },
        {
          title: 'Insurance',
          path: Path.REPORTS_INSURANCE,
          permissions: ['v2:compass:equity:insurance'],
        },
        {
          title: 'Lithium',
          path: Path.REPORTS_LITHIUM,
          permissions: ['v2:compass:equity:lithium'],
        },
        {
          title: 'Macro report',
          path: Path.MACRO_REPORT,
          permissions: ['v2:compass:equity:macro_report'],
        },
        {
          title: 'Metals and mining',
          permissions: ['v2:compass:equity:metals_and_mining'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_METALS_AND_MINING_ANALYSIS,
            },
            {
              title: 'Launchpad',
              path: Path.REPORTS_METALS_AND_MINING_LAUNCHPAD,
            },
            {
              title: 'Files',
              path: Path.REPORTS_METALS_AND_MINING_FILES,
            },
          ],
        },
        {
          title: 'Oil',
          permissions: ['v2:compass:equity:oil'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_OIL_ANALYSIS,
            },
            {
              title: 'Files',
              path: Path.REPORTS_OIL_FILES,
            },
          ],
        },
        {
          title: 'Payments',
          path: Path.REPORTS_PAYMENTS,
          permissions: ['v2:compass:equity:payments'],
        },
        {
          title: 'Proteins',
          permissions: ['v2:compass:equity:proteins'],
          children: [
            {
              title: 'AUS Beef',
              path: Path.REPORTS_PROTEINS_AUS_BEEF,
            },
            {
              title: 'Analysis',
              path: Path.REPORTS_PROTEINS_ANALYSIS,
            },
            {
              title: 'BZ Beef',
              path: Path.REPORTS_PROTEINS_BZ_BEEF,
            },
            {
              title: 'BZ Chicken',
              path: Path.REPORTS_PROTEINS_BZ_CHICKEN,
            },
            {
              title: 'Files',
              path: Path.REPORTS_PROTEINS_FILES,
            },
            {
              title: 'Global Markets',
              path: Path.REPORTS_PROTEINS_GLOBAL_MARKETS,
            },
            {
              title: 'US Beef',
              path: Path.REPORTS_PROTEINS_US_BEEF,
            },
            {
              title: 'US Chicken',
              path: Path.REPORTS_PROTEINS_US_CHICKEN,
            },
            {
              title: 'US Pork',
              path: Path.REPORTS_PROTEINS_US_PORK,
            },
            {
              title: 'Valuation',
              path: Path.REPORTS_PROTEINS_VALUATION,
            },
          ],
        },
        {
          title: 'Pulp and paper',
          permissions: ['v2:compass:equity:pulp_and_paper'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_PULP_AND_PAPER_ANALYSIS,
            },
            {
              title: 'Launchpad',
              path: Path.REPORTS_PULP_AND_PAPER_LAUNCHPAD,
            },
            {
              title: 'Files',
              path: Path.REPORTS_PULP_AND_PAPER_FILES,
            },
          ],
        },
        {
          title: 'Retail',
          path: Path.REPORTS_RETAIL,
          permissions: ['v2:compass:equity:retail'],
        },
        {
          title: 'Saas',
          path: Path.REPORTS_SAAS,
          permissions: ['v2:compass:equity:saas'],
        },
        {
          title: 'Sugar',
          permissions: ['v2:compass:equity:sugar'],
          children: [
            {
              title: 'Analysis',
              path: Path.REPORTS_SUGAR_ANALYSIS,
            },
            {
              title: 'Files',
              path: Path.REPORTS_SUGAR_FILES,
            },
          ],
        },
        {
          title: 'Telecom',
          path: Path.REPORTS_TELECOM,
          permissions: ['v2:compass:equity:telecom'],
        },
        {
          title: 'Utilities',
          path: Path.REPORTS_UTILITIES,
          permissions: ['v2:compass:equity:utilities'],
        },
      ],
    },
    {
      title: 'Alternative data',
      children: [
        {
          title: 'Congresso',
          path: Path.DEPUTIES_VOTES,
          permissions: ['v2:compass:equity:deputies_votes'],
        },
        {
          title: 'Covid',
          path: COMPASS_V1_PATH + '/equity/covid',
        },
        {
          title: 'Covid-19 Mobility Scenarios',
          path: COMPASS_V1_PATH + '/equity/scenarioscovid',
        },
        {
          title: 'Nlp Models',
          path: Path.REPORTS_NLP_MODELS,
          permissions: ['v2:compass:equity:nlp_models'],
        },
        {
          title: 'Satellite',
          path: Path.SATELLITE,
          permissions: ['v2:compass:equity:satellite'],
        },
        {
          title: 'Social Media',
          path: Path.REPORTS_SOCIAL_MEDIA,
          permissions: ['v2:compass:equity:social_media'],
        },
        {
          title: 'Vessels',
          path: Path.VESSELS,
          permissions: ['v2:compass:equity:vessels'],
        },
      ],
    },
    {
      title: 'Presentations',
      children: [
        {
          title: 'Banks',
          path: Path.PRESENTATIONS_BANKS,
        },
        {
          title: 'Coverage',
          children: [
            {
              title: 'Commodities',
              path: Path.PRESENTATIONS_COMMODITIES_COVERAGE,
            },
            { title: 'Urbano Lorea', path: Path.PRESENTATIONS_ULOREA_COVERAGE },
          ],
        },
        {
          title: 'Dividends',
          path: Path.PRESENTATIONS_DIVIDENDS,
        },
        {
          title: 'Hapvida',
          path: Path.PRESENTATIONS_HAPVIDA,
        },
        {
          title: 'Inadimplência',
          path: Path.PRESENTATIONS_BANKS_DEFAULT,
        },
        {
          title: 'Metals and mining',
          path: Path.PRESENTATIONS_METALS_AND_MINING,
        },
        {
          title: 'Multifactor models',
          path: Path.PRESENTATIONS_MULTIFACTOR_MODELS,
        },
        {
          title: 'Norms',
          path: Path.PRESENTATIONS_NORMS,
        },
        {
          title: 'Performance attribution',
          path: Path.PRESENTATIONS_PERFORMANCE_ATTRIBUTION,
        },
        {
          title: 'Portfolio insights',
          path: Path.PRESENTATIONS_PORTFOLIO_INSIGHTS,
        },
        {
          title: 'S&P Valuation',
          path: Path.PRESENTATIONS_VALUATION_SPX,
        },
        {
          title: 'S&P papers',
          path: Path.PRESENTATIONS_SPX_PAPERS,
        },
        {
          title: 'Strategy',
          path: Path.PRESENTATIONS_STRATEGY,
        },
        {
          title: 'Telecom',
          path: Path.PRESENTATIONS_TELECOM,
        },
        {
          title: 'Utilities',
          path: Path.PRESENTATIONS_UTILITIES,
        },
      ],
    },
    {
      title: 'Others',
      children: [
        {
          title: 'Annotation',
          children: [
            {
              title: 'Annotation tool',
              path: Path.ANNOTATION,
            },
          ],
          permissions: ['v2:compass:equity:annotation'],
        },
        {
          title: 'Credit monitor',
          path: COMPASS_V1_PATH + '/credit/creditmonitor',
        },
        {
          title: 'Events',
          path: COMPASS_V1_PATH + '/equity/events',
        },
        {
          title: 'Launchpad monitor',
          path: Path.REPORTS_LAUNCHPAD_MONITOR,
          permissions: ['v2:compass:equity:launchpad_monitor'],
        },
        {
          title: 'Scheduler',
          path: COMPASS_V1_PATH + '/equity/processscheduler',
        },
        {
          title: 'Volatility Analysis',
          path: COMPASS_V1_PATH + '/risk/equityvolanalysis',
        },
        {
          title: 'Volatility Screening',
          path: COMPASS_V1_PATH + '/risk/screeningvol',
        },
      ],
    },
    {
      title: 'Configuration',
      children: [
        {
          title: 'Baskets manager',
          path: Path.BASKETS,
          permissions: ['v2:compass:equity:baskets'],
        },
        {
          title: 'Classification manager',
          path: Path.CLASSIFICATION_MANAGER,
          permissions: ['v2:compass:equity:classification_manager'],
        },
        {
          title: 'Notifications Manager',
          path: Path.NOTIFICATIONS_MANAGER,
          permissions: ['v2:compass:notifications:manager'],
        },
        {
          title: 'User Dashboard',
          path: Path.USER_DASHBOARD,
        },
        {
          title: 'User Notification',
          path: Path.USER_NOTIFICATIONS,
        },
      ],
    },
  ],
}

const realEstateSubtree: SideBarTreeSection = {
  title: 'Real Estate',
  permissions: ['v2:compass:real_estate'],
  children: [
    {
      title: 'Launchpad',
      path: Path.LAUNCHPAD_REAL_ESTATE,
      permissions: ['v2:compass:real_estate:launchpad'],
    },
    {
      title: 'Portfolio',
      path: Path.PORTFOLIO_REAL_ESTATE,
      permissions: ['v2:compass:equity:portfolio'],
    },
  ],
}

export const sideBarTree: SideBarTreeSection = {
  title: 'Menu principal',
  children: [esgSubtree, equitySubtree, realEstateSubtree],
}

export const DEFAULT_PAGE_TITLE = 'Compass v2'
